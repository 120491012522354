<template>
  <div class="loggedInCheckoutDetails">
    <div class="group address">
      <span class="group-title d-block mb-25">
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAACoUlEQVR4nO2cPWsUURSGH4hELSLaqtiJYBcbf4J2ttqIlZUfpa2dEf0DVhYK1lrpH/CjMVYSsFJMLIMGVFA8MjALcVw2ye6de9+5+z7wNlkyc87D5WTPTlgwxhhjjDH/cgC4C2wAX4CV9mcmIUeB10B08hY4YdNpWAY+jpE8yjpw1rJn4yLwfYLkUX4CVyx77yy0Mzj2mAfAvinuN5csAU+nkDzKc+Bw6SbUOQWszSB5lLX2WjuxuO2dTHfur7SvV8d5YDOB5FE222tOYqfx1LxeFVeBXwklj/IbuDXhvt2T3E3zfr0K9gMPexDczRPg4Jj77+Z3q11CoqeMW26qF728wxLSV7rLTdWiL+1yCekr25ebKkUvAPcLCu7mXo2il4BnAnKnyWA4CbwXEFa16HOJl5Cw6P+52S4MMfAw70tIzLPo3EtIzKPoM8AnATFRs+jLwA8BKdFDmt4G+yQkBpaiT24ODXgJiSnyAjiSW/LQl5CYMh+A00N9EhIDyzfgQt+Sa1lCYsb8AW73Ibh5SPlYoMEQy6PUD3DvCDQVomncJGNdoKEQTfOA16LpX/TnlKLnYSkJhdGx2Mr2CKHMfzPFnKUYpRsPiy4vJXyiKS7MowPteEZj0cVPoU805cV5dFBe6iBmtK+TGIvOhEVnwqIzYdGZsOhMWHQmLDoTFp0Ji86ERWfCojOh9iFOiNWTDLXGQqyeZKg1FmL1JEOtsRCrJxlqjYVYPclQayzE6kmGWmMhVk8y1BoLsXqSodZYiNWTDLXGQqyeZKg1FmL1JEOtsRCrJxlbGZoLkXwtKfqNgIDIlFclRd8QEBCZcq301/q8E5AQPWdV4SuOj1cuexU4hgjNyb7ezrEa/kBuAS/bcVH8JBtjjDHGGGOMMcYgxl+nBIeTfLmCTQAAAABJRU5ErkJggg=="
          width="45" />
        {{ $t("shipping_address") }}</span>
      <div v-for="(address, index) in addresses" :key="`u-${index}`">
        <div @click="selectAddress(address.id)" class="address-item border rounded p-5 m-5"
          :class="address.id == selectedShippingAddressId ? 'selected-item' : ''">
          <div v-if="address.company != null">{{ address.company }}</div>
          <div>{{ address.firstname }} {{ address.lastname }}</div>
          <div>{{ address.street[0] }}</div>
          <div>{{ address.postcode }} {{ address.city }}</div>
          <div>{{ address.telephone }}</div>
        </div>
      </div>
      <b-form-checkbox id="new-address" name="new-address" label-for="new-address" v-model="newAddress">{{
        $t("make_new_address") }}</b-form-checkbox>
      <div v-if="newAddress">
        <b-form-checkbox id="checkbox-1" v-model="isCompany" name="checkbox-1">{{ $t("form_are_you_a_company")
          }}</b-form-checkbox>
        <div v-if="isCompany" class="short-form">
          <b-form-group class="account-inputs" id="company-group" :label="$t('form_company_name')" label-for="company">
            <b-form-input 
              v-validParent id="company" :placeholder="$t('form_company_name')" v-model="form.company" type="text"
              v-on:input="validateCompany" v-on:change="validateCompany" :state="form.companyState"
              aria-describedby="company-feedback"></b-form-input>
            <b-form-invalid-feedback id="company-feedback">
              {{ form.companyError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- <b-form-group class="account-inputs" id="coc_number-group" :label="$t('form_coc_number')"
            label-for="coc_number">
            <b-form-input 
              v-validParent id="coc_number" :placeholder="$t('form_coc_number')" v-model="form.coc_number" type="text"
              v-on:input="validateCOCNumber" v-on:change="validateCOCNumber" :state="form.coc_numberState"
              aria-describedby="coc_number-feedback"></b-form-input>
            <b-form-invalid-feedback id="coc_number-feedback">
              {{ form.coc_numberError }}
            </b-form-invalid-feedback>
          </b-form-group> -->
          <b-form-group class="account-inputs" id="taxvat-group" :label="$t('form_vat_number')" label-for="taxvat">
            <b-form-input 
              v-validParent id="taxvat" :placeholder="$t('form_vat_number')" v-model="form.taxvat" type="text"
              :state="form.taxvatState" v-on:change="validateVAT" aria-describedby="taxvat-feedback"></b-form-input>
            <b-form-invalid-feedback id="taxvat-feedback">
              {{ form.taxvatError }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <b-form-group class="account-inputs" id="prefix-group" :label="$t('form_prefix')" label-for="prefix">
          <b-form-select id="prefix" v-model="form.prefix" :options="prefixOptions" :state="form.prefixState"
            class="select" v-on:change="validatePrefix"></b-form-select>
        </b-form-group>
        <b-form-group class="account-inputs" id="first_name-group" :label="$t('form_first_name')"
          label-for="first_name">
          <b-form-input 
            v-validParent id="first_name" :placeholder="$t('form_first_name')" v-model="form.firstname" type="text"
            v-on:input="validateFirstname" v-on:change="validateFirstname" :state="form.firstnameState"
            aria-describedby="firstname-feedback"></b-form-input>
          <b-form-invalid-feedback id="firstname-feedback">
            {{ form.firstnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="account-inputs" id="last_name-group" :label="$t('form_last_name')" label-for="last_name">
          <b-form-input 
            v-validParent id="last_name" :placeholder="$t('form_last_name')" v-model="form.lastname" type="text"
            v-on:input="validateLastname" v-on:change="validateLastname" :state="form.lastnameState"
            aria-describedby="lastname-feedback"></b-form-input>
          <b-form-invalid-feedback id="lastname-feedback">
            {{ form.lastnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="account-inputs" id="country-group" :label="$t('form_country')" label-for="country">
          <b-form-select id="country" v-model="form.address.country_code" :options="countries"
          :state="form.address.country_codeState" v-on:change="validateCountry" class="select"></b-form-select>
        </b-form-group>
        <b-form-group v-if="!showFullAddress" class="account-inputs" id="postcode-group" :label="$t('form_postal_code')"
          label-for="postcode">
          <b-form-input 
            v-validParent id="postcode" :placeholder="$t('form_postal_code')" v-model="form.address.postcode" type="text"
            :state="form.address.postcodeState" v-on:input="validatePostCode" v-on:change="validatePostCode"
            aria-describedby="address.postcode-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.postcode-feedback">
            {{ form.address.postcodeError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-show="showFullAddress" class="account-inputs" id="street-group" :label="$t('form_street')"
          label-for="street">
          <b-form-input 
            v-validParent id="street" :placeholder="$t('form_street')" v-model="form.address.streetDisplay" type="text"
            v-on:input="validateStreet" v-on:change="validateStreet" :state="form.address.streetDisplayState"
            aria-describedby="address.streetDisplay-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.streetDisplay-feedback">
            {{ form.address.streetDisplayError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-row>
          <b-col sm="6" class="first">
            <b-form-group class="account-inputs" id="house_number-group" :label="$t('form_house_number')"
              label-for="house_number">
              <b-form-input 
                v-validParent id="house_number" :placeholder="$t('form_company_name')" v-model="form.address.house_number" type="text" v-on:input="validateCOCNumber" v-on:change="validateCOCNumber"
                v-on:blur="validateHouseNumber()" :state="form.address.house_numberState"
                aria-describedby="address.house_number-feedback"></b-form-input>
              <b-form-invalid-feedback id="address.house_number-feedback">
                {{ form.address.house_numberError }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col sm="6" class="third">
            <b-form-group class="account-inputs" id="addition-group" :label="$t('form_addition')" label-for="addition">
              <b-form-input 
                v-validParent id="addition" :placeholder="$t('form_company_name')" v-model="form.address.addition" type="text"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- <b-form-group class="account-inputs" id="house_number-group" :label="$t('form_house_number_and_addition')"
          label-for="invoice_house_number_and_addition">
          <b-form-input 
            v-validParent :placeholder="$t('form_house_number_and_addition')" id="invoice_house_number_and_addition"
            name="house_number_and_addition" v-model="form.address.house_number_and_addition" type="text"
            v-on:input="validateHouseNumberAndAddition" v-on:change="validateHouseNumberAndAddition"
            :state="form.address.house_number_and_additionState"
            aria-describedby="address.house_number_and_addition-feedback" ref="addressHouseNumber"></b-form-input>
          <b-form-invalid-feedback id="address.house_number_and_addition-feedback">
            {{ form.address.house_number_and_additionError }}
          </b-form-invalid-feedback>
        </b-form-group> -->

        <b-form-group v-show="showFullAddress" class="account-inputs" id="postcode-group1"
          :label="$t('form_postal_code')" label-for="postcode1">
          <b-form-input 
            v-validParent id="postcode1" :placeholder="$t('form_postal_code')" v-model="form.address.postcode" type="text"
            v-on:input="validatePostCode" v-on:change="validatePostCode" :state="form.address.postcodeState"
            aria-describedby="address.postcode1-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.postcode1-feedback">
            {{ form.address.postcodeError }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group v-show="showFullAddress" class="account-inputs" id="city-group" :label="$t('form_city')"
          label-for="city">
          <b-form-input 
            v-validParent id="city" :placeholder="$t('form_city')" v-model="form.address.city" type="text"
            v-on:input="validateCity" v-on:change="validateCity" :state="form.address.cityState"
            aria-describedby="address.city-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.city-feedback">
            {{ form.address.cityError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-if="foundAddress != '' && showFullAddress == false" class="account-inputs" id="add-group"
          label=" " label-for="add">
          <div id="add"  v-html="foundAddress"></div>
        </b-form-group>
        <b-form-group v-if="foundAddresses.length > 0 && showFullAddress == false" class="account-inputs"
          id="addm-group" label=" " label-for="addm">
          <div id="addm">
            <b-form-radio  class="account-radios label-fw-100" v-model="selectedAddress"
              v-for="(item, index) in foundAddresses" :key="index" name="some-radios" :value="index">{{ item.text
              }}</b-form-radio>
          </div>
        </b-form-group>
        <b-form-checkbox v-if="showManual" id="manual" v-model="showFullAddress" name="manual">{{
          $t("form_fillout_address_manual") }}</b-form-checkbox>
        <b-form-group class="account-inputs" id="telephone-group" :label="$t('form_phone')" label-for="telephone">
          <b-form-input 
            v-validParent id="telephone" :placeholder="$t('form_phone')" v-model="form.address.telephone" type="text"
            v-on:input="validatePhone" v-on:change="validatePhone" :state="form.address.telephoneState"
            aria-describedby="address.telephone-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.telephone-feedback">
            {{ form.address.telephoneError }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <b-form-checkbox id="checkbox-2" name="checkbox-2" label-for="checkbox2" v-model="form.address.default_billing">{{
        $t("form_address_also_invoice_address") }}</b-form-checkbox>
    </div>
    <div class="group" v-if="form.address.default_billing == false">
      <span class="group-title d-block mb-25"><i class="lnr lnr-home mr-10"></i>{{ $t("invoice_address") }}</span>

      <div v-for="(address, index) in addresses" :key="`u-${index}`">
        <div @click="selectInvoiceAddress(address.id)" class="address-item border rounded p-5 m-5"
          :class="address.id == selectedBillingAddressId ? 'selected-item' : ''">
          <div v-if="address.company != null">{{ address.company }}</div>
          <div>{{ address.firstname }} {{ address.lastname }}</div>
          <div>{{ address.street[0] }}</div>
          <div>{{ address.postcode }} {{ address.city }}</div>
          <div>{{ address.telephone }}</div>
        </div>
      </div>
      <b-form-checkbox id="new-invoice-address" name="new-invoice-address" label-for="new-invoice-address"
        v-model="newInvoiceAddress">{{ $t("make_new_address") }}</b-form-checkbox>
      <div v-if="newInvoiceAddress">
        <b-form-checkbox id="checkbox-99" v-model="isInvoiceCompany" name="checkbox-99">{{ $t("form_are_you_a_company")
          }}</b-form-checkbox>
        <div v-if="isInvoiceCompany" class="short-form">
          <b-form-group class="account-inputs" id="company-group" :label="$t('form_company_name')" label-for="company">
            <b-form-input 
              v-validParent id="company" :placeholder="$t('form_company_name')" v-model="form.invoice.company" type="text"
              v-on:input="validateInvoiceCompany" v-on:change="validateInvoiceCompany"
              :state="form.invoice.companyState" aria-describedby="company-feedback"></b-form-input>
            <b-form-invalid-feedback id="company-feedback">
              {{ form.invoice.companyError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- <b-form-group class="account-inputs" id="coc_number-group" :label="$t('form_coc_number')"
            label-for="coc_number">
            <b-form-input 
              v-validParent id="coc_number" :placeholder="$t('form_coc_number')" v-model="form.invoice.coc_number"
              type="text" v-on:input="validateInvoiceCOCNumber" v-on:change="validateInvoiceCOCNumber"
              :state="form.invoice.coc_numberState" aria-describedby="coc_number-feedback"></b-form-input>
            <b-form-invalid-feedback id="coc_number-feedback">
              {{ form.invoice.coc_numberError }}
            </b-form-invalid-feedback>
          </b-form-group> -->
          <b-form-group class="account-inputs" id="taxinvoicevat-group" :label="$t('form_vat_number')" label-for="taxvat">
            <b-form-input 
              v-validParent id="taxinvoicevat" :placeholder="$t('form_vat_number')" v-model="form.invoice.taxvat" type="text"
              :state="form.invoice.taxvatState" v-on:change="validateInvoiceVAT"
              aria-describedby="taxvat-feedback"></b-form-input>
            <b-form-invalid-feedback id="taxvat-feedback">
              {{ form.invoice.taxvatError }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <b-form-group class="account-inputs" id="prefix-group" :label="$t('form_prefix')" label-for="prefix">
          <b-form-select id="prefix" v-model="form.invoice.prefix" :options="prefixOptions"
            :state="form.invoice.prefixState" class="select" v-on:change="validateInvoicePrefix"></b-form-select>
        </b-form-group>
        <b-form-group class="account-inputs" id="first_name-group" :label="$t('form_first_name')"
          label-for="first_name">
          <b-form-input 
            v-validParent id="first_name" :placeholder="$t('form_first_name')" v-model="form.invoice.firstname"
            type="text" v-on:input="validateInvoiceFirstname" v-on:change="validateInvoiceFirstname"
            :state="form.invoice.firstnameState" aria-describedby="firstname-feedback"></b-form-input>
          <b-form-invalid-feedback id="firstname-feedback">
            {{ form.invoice.firstnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="account-inputs" id="last_name-group" :label="$t('form_last_name')" label-for="last_name">
          <b-form-input 
            v-validParent id="last_name" :placeholder="$t('form_last_name')" v-model="form.invoice.lastname" type="text"
            v-on:input="validateInvoiceLastname" v-on:change="validateInvoiceLastname"
            :state="form.invoice.lastnameState" aria-describedby="lastname-feedback"></b-form-input>
          <b-form-invalid-feedback id="lastname-feedback">
            {{ form.invoice.lastnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="account-inputs" id="country-group" :label="$t('form_country')" label-for="country">
          <b-form-select id="country" v-model="form.invoice.address.country_code" :options="countries"
          :state="form.invoice.address.country_codeState" v-on:change="validateInvoiceCountry" class="select"></b-form-select>
        </b-form-group>
        <b-form-group v-if="!showFullInvoiceAddress" class="account-inputs" id="postcode-group"
          :label="$t('form_postal_code')" label-for="postcode">
          <b-form-input 
            v-validParent id="postcode" :placeholder="$t('form_postal_code')" v-model="form.invoice.address.postcode"
            type="text" v-on:input="validateInvoicePostcode" v-on:change="validateInvoicePostcode"
            :state="form.invoice.address.postcodeState" aria-describedby="address.postcode-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.postcode-feedback">
            {{ form.invoice.address.postcodeError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-if="showFullInvoiceAddress" class="account-inputs" id="street-group" :label="$t('form_street')"
          label-for="street">
          <b-form-input 
            v-validParent id="street" :placeholder="$t('form_street')" v-model="form.invoice.address.streetDisplay"
            type="text" v-on:input="validateInvoiceStreet" v-on:change="validateInvoiceStreet"
            :state="form.invoice.address.streetDisplayState"
            aria-describedby="address.streetDisplay-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.streetDisplay-feedback">
            {{ form.invoice.address.streetDisplayError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-row>
          <b-col sm="6" class="first">
            <b-form-group class="account-inputs" id="house_number-group" :label="$t('form_house_number')"
              label-for="house_number">
              <b-form-input 
                v-validParent id="house_number" :placeholder="$t('form_house_number')" v-model="form.invoice.address.house_number" type="text" v-on:input="validateCOCNumber" v-on:change="validateCOCNumber"
                v-on:blur="validateInvoiceHouseNumber()" :state="form.invoice.address.house_numberState"
                aria-describedby="address.house_number-feedback"></b-form-input>
              <b-form-invalid-feedback id="address.house_number-feedback">
                {{ form.invoice.address.house_numberError }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col sm="6" class="third">
            <b-form-group class="account-inputs" id="addition-group" :label="$t('form_addition')" label-for="addition">
              <b-form-input 
                v-validParent id="addition" :placeholder="$t('form_addition')" v-model="form.invoice.address.addition" type="text"></b-form-input> 
            </b-form-group>
          </b-col>
        </b-row>

        <!-- <b-form-group class="account-inputs" id="house_number-group" :label="$t('form_house_number_and_addition')"
          label-for="house_number_and_addition">
          <b-form-input 
            v-validParent :placeholder="$t('form_house_number_and_addition')" id="house_number_and_addition"
            name="house_number_and_addition" v-model="form.invoice.address.house_number_and_addition" type="text"
            v-on:input="validateInvoiceHouseNumberAndAddition" v-on:change="validateInvoiceHouseNumberAndAddition"
            :state="form.invoice.address.house_number_and_additionState"
            aria-describedby="form.invoice.address.house_number_and_addition-feedback" ref="addressHouseNumber"></b-form-input>
          <b-form-invalid-feedback id="form.invoice.address.house_number_and_addition-feedback">
            {{ form.invoice.address.house_number_and_additionError }}
          </b-form-invalid-feedback>
        </b-form-group> -->


        <b-form-group v-if="showFullInvoiceAddress" class="account-inputs" id="postcode-group1"
          :label="$t('form_postal_code')" label-for="postcode1">
          <b-form-input 
            v-validParent id="postcode1" :placeholder="$t('form_postal_code')" v-model="form.invoice.address.postcode"
            type="text" v-on:input="validateInvoicePostcode" v-on:change="validateInvoicePostcode"
            :state="form.invoice.address.postcodeState" aria-describedby="address.postcode1-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.postcode1-feedback">
            {{ form.invoice.address.postcodeError }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group v-if="showFullInvoiceAddress" class="account-inputs" id="city-group" :label="$t('form_city')"
          label-for="city">
          <b-form-input 
            v-validParent id="city" :placeholder="$t('form_city')" v-model="form.invoice.address.city" type="text"
            v-on:input="validateInvoiceCity" v-on:change="validateInvoiceCity" :state="form.invoice.address.cityState"
            aria-describedby="address.city-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.city-feedback">
            {{ form.invoice.address.cityError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-if="foundInvoiceAddress != '' && showFullInvoiceAddress == false" class="account-inputs"
          id="add-group" label=" " label-for="add">
          <div id="add" v-html="foundInvoiceAddress"> </div>
        </b-form-group>
        <b-form-group v-if="
          foundInvoiceAddresses.length > 0 && showFullInvoiceAddress == false" 
          class="account-inputs" id="addm-group" label=" " label-for="addm">
          <div id="addm">
            <b-form-radio class="account-radios label-fw-100" v-model="selectedInvoiceAddress"
              v-for="(item, index) in foundInvoiceAddresses" :key="index" name="some-radios" :value="index">{{ item.text
              }}</b-form-radio>
          </div>
        </b-form-group>
        <b-form-checkbox v-if="showManual" id="manual2" v-model="showFullInvoiceAddress" name="manual2">{{
          $t("form_fillout_address_manual") }}</b-form-checkbox>
        <b-form-group class="account-inputs" id="telephone-group" :label="$t('form_phone')" label-for="telephone">
          <b-form-input 
            v-validParent id="telephone" :placeholder="$t('form_phone')" v-model="form.invoice.address.telephone"
            type="text" v-on:input="validateInvoicePhone" v-on:change="validateInvoicePhone"
            :state="form.invoice.address.telephoneState" aria-describedby="address.telephone-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.telephone-feedback">
            {{ form.invoice.address.telephoneError }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@config";
import {
  validatePostcode,
  validateVatNumber,
  checkEmailExist,
} from "@storefront/core/data-resolver/user";
import Cart from "@storefront/core/modules/cart/mixins";

export default {
  name: "LoggedInCheckoutDetails",
  mixins: [Cart],
  mounted() {
    if (typeof config.customers.defaultCountry != "undefined") {
      this.form.address.country_code = config.customers.defaultCountry;
      this.form.invoice.address.country_code = config.customers.defaultCountry;
    }
    if (config.postcode_validation.enabled == false) {
      this.manual = true;
      this.showManual = false;
    }
    if (config.vat_validation.enabled == false) {
      this.showVatCheck = false;
    }
    this.$root.$on("checkFields", () => {
      if( this.$store.getters['user/getIsLoggedIn'] == true){
        this.checkFieldsLoggedin();
      }
    });
  },
  methods: {
    async checkDeliveryAddressFields() {
      this.validateCompany();
      this.validateVAT();
      this.validateEmail();
      this.validateFirstname();
      this.validateLastname();
      this.validateAddress();
      this.validateHouseNumber();
      this.validatePhone();
      this.validateStreet();
      this.validatePostCode();
      this.validateCity();
      await this.checkFieldsLoggedin()
    },
    async checkBillingAddressFields() {
      if (this.form.address.default_billing == false) {
        this.validateInvoiceCompany();
        this.validateInvoiceVAT();
        this.validateInvoiceAddress();
        this.validateInvoiceFirstname();
        this.validateInvoiceLastname();
        this.validateInvoiceCountry();
        this.validateInvoiceStreet();
        this.validateInvoiceHouseNumber();
        this.validateInvoicePostcode();
        this.validateInvoiceCity();
        this.validateInvoicePhone();
        await this.checkFieldsLoggedin()
      }
    },
    checkFieldsLoggedin() {
      var form = this.form;
      var errorFields = [];
      var skipFields = [
        "email",
        "taxvat",
        "password",
        "password_confirm",
        "coc_number",
        "house_number"
      ];
      Object.keys(form).forEach((name) => {
        if (typeof form[name] == "object" && !Array.isArray(form[name]) && form[name] !== null) {
          if (name == "invoice" && (form.address.default_billing == true && this.newInvoiceAddress != true)) {
            return;
          }
          Object.keys(form[name]).forEach((child) => {
            if (child == "companyState" && name == "address") {
              if (!this.isCompany) {
                return;
              }
            }
            if (child == "companyState" && name == "invoice") {
              if (!this.isInvoiceCompany) {
                return;
              }
            }
            if (child.includes("State") && (form[name][child] == false || form[name][child] == null)) {
              if (child == "companyState" && name == "address") {
                if (!this.isCompany) {
                  return;
                }
              }
              if (child == "companyState" && name == "invoice") {
                if (!this.isInvoiceCompany) {
                  return;
                }
              }
              if (skipFields.includes(child.replace("State", ""))) {
                return;
              }
              switch (name) {
                case "invoice":
                  child = "invoice_" + child;
                  break;
                case "address":
                  if (this.newAddress == true) {
                    child = "shipping_" + child;
                  }
                  break;
              }
              errorFields.push(this.$t(child.replace("State", "")));
            }
          });
        } else {
          if (name.includes("State") && (form[name] == false || form[name] == null)) {
            if (name == "companyState" || name == "taxvatState") {
              if (!this.isCompany) {
                return;
              }
            }
            if (skipFields.includes(name.replace("State", ""))) {
              return;
            }
            errorFields.push(this.$t(name.replace("State", "")));
          }
        }
        if (errorFields.length > 0) {
          this.$store.commit("cart/setStoreValidation", {
            address: errorFields,
          });
        } else {
          this.$store.commit("cart/setStoreValidation", {
            address: [],
          });
        }
      });

    },
    async validateEmail() {
      if (this.form.email.length == 0) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.form.email)) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      if (!(await checkEmailExist(this.form.email))) {
        this.emailExists = true;
      } else {
        this.emailExists = false;
      }

      this.form.emailState = true;
      this.$store.dispatch("cart/addCartEmail", {
        email: this.form.email,
      });
      return true;
    },
    async doLogin() {
      const loginOk = await this.$store.dispatch("user/login", {
        username: this.form.email,
        password: this.form.password,
      });

      if (loginOk == true) {
        const msg = {
          type: "success",
          title: this.$t("login_success"),
          text: this.$t("you_are_logged_in"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
    async validateVAT() {
      if (this.isCompany == true && this.form.taxvat != undefined) {
        if (this.form.taxvat.trim() != "") {
          if (this.form.taxvat.substring(0, 2).toUpperCase() === this.form.address.country_code) {
            const retval = await validateVatNumber(this.form.taxvat);
            if (retval.is_valid == true) {
              this.isValidVatNumber = true;
              this.form.taxvatState = true;
            } else {
              this.isValidVatNumber = false;
              this.form.taxvatState = false;
              this.form.taxvatError = this.$t("taxvat_invalid");

              this.showVatMessage = this.$t("taxvat_invalid");
            }
          } else {
            this.isValidVatNumber = false;
            this.form.taxvatState = false;
            this.form.taxvatError = this.$t("taxvat_wrong_country");
          }
        } else if(this.form.taxvat == "") {
          this.form.taxvatState = null;
          this.form.taxvatError = "";
        }
      } else {
        this.form.taxvatState = null;
      }
    },
    async validateAddress() {
      let isOk = true;

      if (this.isCompany == true) {
        if (!this.form.companyState) {
          isOk = false;
          console.log("company error");
        }
        this.addressState.companyState = this.form.companyState;
        if (!this.form.taxvatState && this.form.taxvatState != null) {
          isOk = false;
          console.log("TaxVat error");
        }
      }
      if (!this.form.prefixState) {
        isOk = false;
        console.log("prefix error");
      }
      if (!this.form.firstnameState) {
        isOk = false;
        console.log("first name error");
      }
      this.addressState.firstnameState = this.form.firstnameState;
      if (!this.form.lastnameState) {
        isOk = false;
        console.log("last name error");
      }
      this.addressState.lastnameState = this.form.lastnameState;

      if (!this.form.address) {
        this.validatePostCode();
        this.validateHouseNumber();
        // this.validateHouseNumberAndAddition();
        this.validateHouseNumber();
        this.validateCity();
        this.validateStreet();
        this.validatePhone();
        isOk = false;
        this.addressState.address = !!this.form.address;
      } else {
        if (
          !this.form.address.country_code ||
          this.form.address.country_code == ""
        ) {
          this.addressState.country_code = false;
          isOk = false;
        } else {
          this.addressState.country_code = true;
        }
        if (!this.form.address.postcodeState) {
          isOk = false;
          console.log("postcode error");
        }
        this.addressState.postcodeState = this.form.address.postcodeState;

        if (!this.showFullAddress) {
          if (!this.form.address.streetDisplayState) {
            isOk = false;
            console.log("street error");
          }
          this.addressState.streetDisplayState =
            this.form.address.streetDisplayState;
        }
        if (!this.form.address.house_numberState) {
          isOk = false;
          console.log("house number error");
        }
        this.addressState.house_numberState =
          this.form.address.house_numberState;

        if (!this.form.address.cityState) {
          isOk = false;
          console.log("city error");
        }
        this.addressState.cityState = this.form.address.cityState;

        if (!this.form.address.telephoneState) {
          isOk = false;
          console.log("phone error");
        }
        this.addressState.telephoneState = this.form.address.telephoneState;
      }
      this.addressState.isOk = isOk;
      if (isOk == true) {
        this.$store.dispatch('setLoading', true) //Set Loader
        const address = {
          prefix: this.form.prefix,
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          postcode: this.form.address.postcode,
          city: this.form.address.city,
          telephone: this.form.address.telephone,
          street: `${this.form.address.streetDisplay}\n${this.form.address.house_number}\n${this.form.address.addition}`,
          country_code: this.form.address.country_code,
        };
        if (this.isCompany == true) {
          address.company = this.form.company;
          if (this.form.taxvat != "") {
            address.vat_id = this.form.taxvat;
          }
        }
        if(this.newAddress){
          await this.$store.dispatch("cart/addShippingAddress", {
            address: address,
            reloadCart: false,
          });
          if (this.form.address.default_billing == true) {
            await this.$store.dispatch("cart/addBillingAddress", {
              address: address,
              reloadCart: true,
            });
          }
        }
        this.$store.dispatch('setLoading', false)// Unset Loader
      } else {
        this.$store.commit("cart/setShippingMethods", []);
      }
    },
    async validateInvoiceAddress() {
      let isOk = true;
      if (this.isInvoiceCompany == true) {
        if (!this.form.invoice.companyState) {
          isOk = false;
        }
        if (!this.form.invoice.taxvatState && this.form.invoice.taxvatState != null) {
          isOk = false;
        }
      }
      if (!this.form.invoice.firstnameState) {
        isOk = false;
      }
      if (!this.form.invoice.lastnameState) {
        isOk = false;
      }
      if (this.form.invoice.address.country_code == "") {
        isOk = false;
      }
      if (!this.form.invoice.address.postcodeState) {
        isOk = false;
      }
      if (this.form.invoice.address.streetDisplay == "") {
        isOk = false;
      }
      if (this.form.invoice.address.house_number == "") {
        isOk = false;
      }
      if (this.form.invoice.address.city == "") {
        isOk = false;
      }
      if (this.form.invoice.address.telephone == "") {
        isOk = false;
      }

      if (isOk == true) {
        this.$store.dispatch('setLoading', true)// Set Loader
        const address = {
          prefix: this.form.invoice.prefix,
          firstname: this.form.invoice.firstname,
          lastname: this.form.invoice.lastname,
          postcode: this.form.invoice.address.postcode,
          city: this.form.invoice.address.city,
          telephone: this.form.invoice.address.telephone,
          street: `${this.form.invoice.address.streetDisplay}\n${this.form.invoice.address.house_number}\n${this.form.invoice.address.addition}`,
          country_code: this.form.invoice.address.country_code,
        };
        if (this.isInvoiceCompany == true) {
          address.company = this.form.invoice.company;
            address.vat_id = this.form.invoice.taxvat;
        }
        if(this.newInvoiceAddress){
          await this.$store.dispatch("cart/addBillingAddress", {
            address: address,
          });
        }
        this.$store.dispatch('setLoading', false)// Unset Loader
      } else {
        this.$store.commit("cart/setShippingMethods", []);
      }
    },
    validateCompany() {
      if (this.isCompany == true) {
        if (this.form.company == "" || this.form.company == undefined) {
          this.form.companyState = false;
          this.form.companyError = this.$t("company_name_required");
        } else {
          this.form.companyState = true;
        }
      } else {
        this.form.companyState = null;
      }
    },
    async validatePrefix() {
      this.form.prefixState = true;
      await this.validateAddress();
    },
    async validateInvoicePrefix() {
      this.form.invoice.prefixState = true;
      await this.validateInvoiceAddress();
    },
    validateFirstname() {
      if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
      }
    },
    validateLastname() {
      if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
      }
    },
    validateCountry() {
      this.form.address.country_codeState = true
      this.validateVAT();

      if (this.form.address.postcode != "") {
        this.validatePostCode();
      }
    },
    // validateCOCNumber() {
    //   if (this.isCompany == true) {
    //     if (this.form.coc_number == "") {
    //       this.form.coc_numberState = false;
    //       this.form.coc_numberError = this.$t("coc_number_required");
    //     } else {
    //       this.form.coc_numberState = true;
    //     }
    //   } else {
    //     this.form.coc_numberState = null;
    //   }
    // },
    validateStreet() {
      if (this.form.address.streetDisplay == "") {
        this.form.address.streetDisplayState = false;
        this.form.address.streetDisplayError = this.$t("street_required");
      } else {
        this.form.address.streetDisplayState = true;
      }
    },
    validateHouseNumber() {
      if (this.form.address.house_number == "") {
        this.form.address.house_numberState = false;
        this.form.address.house_numberError = this.$t("house_number_required");
      } else if (this.foundAddresses.length > 0 && this.selectedAddress == null){
        this.form.address.house_numberState = null;
      } else if(this.showFullAddress == false && this.selectedAddress == null && this.foundAddress == null){
        this.form.address.house_numberState = false;
      } else {
        this.form.address.house_numberState = true;
      }
      this.validateInput(event, this.form.address.house_numberState);
    },
    // validateHouseNumberAndAddition() {
    //   if (this.form.address.house_number_and_addition == "") {
    //     this.form.address.house_number_and_additionState = false;
    //     this.form.address.house_number_and_additionError = this.$t("house_number_and_addition_required");
    //   } else if (this.foundAddresses.length > 0 && this.selectedAddress == null){
    //     this.form.address.house_number_and_additionState = null;
    //   } else {
    //     this.form.address.house_number_and_additionState = true;
    //   }
    // },
    validateCity() {
      if (this.form.address.city == "") {
        this.form.address.cityState = false;
        this.form.address.cityError = this.$t("city_required");
      } else {
        this.form.address.cityState = true;
      }
    },
    validatePhone() {
      if (this.form.address.telephone == "") {
        this.form.address.telephoneState = false;
        this.form.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.address.telephoneState = true;
      }
    },
    async validatePostcodeHouseNumber() {
      if (this.showFullAddress == false) {
        const list = await validatePostcode(
          this.form.address.postcode,
          this.form.address.house_number
        );
        if (list.length == 1) {
          this.foundAddresses = [];
          this.form.address.house_numberError = '';
          let address = list[0].street + " " + list[0].number;
          this.form.address.streetDisplay = list[0].street;
          let singleAddition = "";
          if (list[0].letter != null) {
            singleAddition = list[0].letter;
            address = address + list[0].letter;
          }
          if(singleAddition != ""){
            this.form.address.addition = singleAddition;
            this.form.address.additionState = true
          } else {
              this.form.address.addition = ''
              this.form.address.additionState = null
          }
          address = address + ", " + list[0].postcode + " " + list[0].city;

          this.form.address.city = list[0].city;
          this.form.address.cityState = true;
          this.foundAddress = address;
          this.validatePostCode();
          // this.validateHouseNumberAndAddition();
          this.validateHouseNumber();
          this.form.address.postcodeState = true;
          this.form.address.house_numberState = true;
          // this.form.address.house_number_and_additionState = true;
          this.form.address.cityState = true;
          this.form.address.streetDisplayState = true;
        } else if (list.length == 0) {
          this.foundAddresses = [];
          this.foundAddress = '';
          this.form.address.house_numberError = this.$t("postcode_not_found");
          this.form.address.house_numberState = false;
          this.form.address.addition = '';
          this.form.address.additionState = null;
        } else if (list.length > 1) {
          if(this.foundAddresses.length > 0){
            this.foundAddresses = [];
          }
          this.form.address.house_numberError = '';
          list.forEach(function (item) {
            let address = item.street + " " + item.number;
            if (item.letter != null) {
              address = address + " "  + item.letter;
            }
            if (item.addition != null) {
              address =
                address + (item.letter == null ? " " : "") + item.addition;
            }
            address = address + ", " + item.postcode + " " + item.city;
            item.text = address;
            this.foundAddresses.push(item);
          }, this);
          // Sort foundAddresses based on letter and addition
          this.foundAddresses.sort(function (a, b) {
            // Handle null values for letter (treat null as empty string)
            const aLetter = a.letter || '';
            const bLetter = b.letter || '';
            const letterCompare = aLetter.localeCompare(bLetter);
            if (letterCompare !== 0) {
              return letterCompare;
            }
            // Handle null values for addition (treat null as 0)
            const aAddition = parseInt(a.addition) || 0;
            const bAddition = parseInt(b.addition) || 0;

            // If letter is the same, sort by addition (numeric order)
            return aAddition - bAddition;
          });
          if(this.selectedAddress === null){
            this.form.address.house_numberState = null;
          } else {
            let addition = "";
            if(this.foundAddresses[this.selectedAddress] != undefined){
              if (this.foundAddresses[this.selectedAddress].letter != null) {
                addition = this.foundAddresses[this.selectedAddress].letter;
              }
              if (this.foundAddresses[this.selectedAddress].addition != null) {
                addition += this.foundAddresses[this.selectedAddress].letter !== null ? (!isNaN(this.foundAddresses[this.selectedAddress].letter) && !isNaN(this.foundAddresses[this.selectedAddress].addition) ? "-" : "") + this.foundAddresses[this.selectedAddress].addition : this.foundAddresses[this.selectedAddress].addition;
              }
              let houseNumber = `${this.foundAddresses[this.selectedAddress].number}`;
              if(this.form.address.house_number != houseNumber){
                this.selectedAddress = null;
                this.form.address.house_numberState = null;
              }
              if(this.form.address.addition == '' 
              || this.form.address.addition == null
              || this.form.address.addition != addition
              ){
                  this.form.address.additionState = null;
                this.selectedAddress = null;
              }
            } else {
              this.selectedAddress = null;
              this.form.address.house_numberState = null;
              this.form.address.additionState = null;
            }
          }
        }
      } else {
        if (this.form.address.postcode == "") {
          this.form.address.postcodeState = false;
          this.form.address.postcodeError = this.$t("postcode_required");
        } else {
          this.form.address.postcodeState = true;
        }
      }
    },
    validatePostCode() {
      const status = this.$helpers.validatePostcode(this.form.address.postcode, this.form.address.country_code);
      this.form.address.postcodeState = status

      if (status == false) {
        this.form.address.postcodeError = this.$t("postcode_required")
      }
    },
    async validateInvoiceVAT() {
      if (this.isInvoiceCompany == true && this.form.invoice.taxvat != undefined) {
        if (this.form.invoice.taxvat.trim() != "") {
          if (
            this.form.invoice.taxvat.substring(0, 2).toUpperCase() ===
            this.form.invoice.address.country_code
          ) {
            const retval = await validateVatNumber(this.form.invoice.taxvat);
            if (retval.is_valid == true) {
              this.isValidInvoiceVatNumber = true;
              this.form.invoice.taxvatState = true;
            } else {
              this.isValidInvoiceVatNumber = false;
              this.form.invoice.taxvatState = false;
              this.form.invoice.taxvatError = this.$t("taxvat_invalid");
            }
          } else {
            this.isValidInvoiceVatNumber = false;
            this.form.invoice.taxvatState = false;
            this.form.invoice.taxvatError = this.$t("taxvat_wrong_country");
          }
        } else if(this.form.invoice.taxvat == ""){
          this.form.invoice.taxvatState = null;
          this.form.invoice.taxvatError = "";
        }
      } else {
        this.form.invoice.taxvatState = null;
      }
    },
    validateInvoiceCompany() {
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.company == "" || this.form.invoice.company == undefined) {
          this.form.invoice.companyState = false;
          this.form.invoice.companyError = this.$t("company_name_required");
        } else {
          this.form.invoice.companyState = true;
        }
      } else {
        this.form.invoice.companyState = null;
      }
    },
    validateInvoiceFirstname() {
      if (this.form.invoice.firstname == "") {
        this.form.invoice.firstnameState = false;
        this.form.invoice.firstnameError = this.$t("firstname_required");
      } else {
        this.form.invoice.firstnameState = true;
      }
    },
    validateInvoiceLastname() {
      if (this.form.invoice.lastname == "") {
        this.form.invoice.lastnameState = false;
        this.form.invoice.lastnameError = this.$t("lastname_required");
      } else {
        this.form.invoice.lastnameState = true;
      }
    },
    // validateInvoiceCOCNumber() {
    //   if (this.isInvoiceCompany == true) {
    //     if (this.form.invoice.coc_number == "") {
    //       this.form.invoice.coc_numberState = false;
    //       this.form.invoice.coc_numberError = this.$t("coc_number_required");
    //     } else {
    //       this.form.invoice.coc_numberState = true;
    //     }
    //   } else {
    //     this.form.invoice.coc_numberState = null;
    //   }
    // },
    validateInvoiceCountry() {
      this.form.invoice.address.country_codeState = true
      this.validateInvoiceVAT();

      if (this.form.invoice.address.postcode != "") {
        this.validateInvoicePostcode();
      }
    },
    validateInvoiceStreet() {
      if (this.form.invoice.address.streetDisplay == "") {
        this.form.invoice.address.streetDisplayState = false;
        this.form.invoice.address.streetDisplayError =
          this.$t("street_required");
      } else {
        this.form.invoice.address.streetDisplayState = true;
      }
    },
    validateInvoiceHouseNumber() {
      if (this.form.invoice.address.house_number == "") {
        this.form.invoice.address.house_numberState = false;
        this.form.invoice.address.house_numberError = this.$t("house_number_required");
      } else if (this.foundInvoiceAddresses.length > 0 && this.selectedInvoiceAddress == null){
        this.form.invoice.address.house_numberState = null;
      } else if(this.showFullInvoiceAddress == false && this.selectedInvoiceAddress == null && this.foundInvoiceAddress == null){
        this.form.invoice.address.house_numberState = false;
      } else {
        this.form.invoice.address.house_numberState = true;
        this.form.invoice.address.house_numberError = "";
        this.updateLocalStorage({
          invoiceCoc: this.form.invoice.address.house_number,
          invoiceCocState: this.form.invoice.address.house_numberState,
        });
      }
    },
    // validateInvoiceHouseNumberAndAddition() {
    //   if (this.form.invoice.address.house_number_and_addition == "") {
    //     this.form.invoice.address.house_number_and_additionState = false;
    //     this.form.invoice.address.house_number_and_additionError = this.$t("house_number_and_addition_required");
    //   } else if (this.foundInvoiceAddresses.length > 0 && this.selectedInvoiceAddress == null){
    //     this.form.invoice.address.house_number_and_additionState = null;
    //   } else {
    //     this.form.invoice.address.house_number_and_additionState = true;
    //     this.form.invoice.address.house_number_and_additionError = "";
    //   }
    // },
    validateInvoiceCity() {
      if (this.form.invoice.address.city == "") {
        this.form.invoice.address.cityState = false;
        this.form.invoice.address.cityError = this.$t("city_required");
      } else {
        this.form.invoice.address.cityState = true;
      }
    },
    validateInvoicePhone() {
      if (this.form.invoice.address.telephone == "") {
        this.form.invoice.address.telephoneState = false;
        this.form.invoice.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.invoice.address.telephoneState = true;
      }
    },
    async validateInvoicePostcodeHouseNumber() {
      if (this.showFullInvoiceAddress == false) {
        const list = await validatePostcode(
          this.form.invoice.address.postcode,
          this.form.invoice.address.house_number
        );
        if (list.length == 1) {
          this.foundInvoiceAddresses = [];
          this.form.invoice.address.house_numberError = '';
          let address = list[0].street + " " + list[0].number;
          this.form.invoice.address.streetDisplay = list[0].street;
          this.form.invoice.address.streetDisplayState = true;
          let singleInvoiceAddition = "";
          if (list[0].letter != null) {
            singleInvoiceAddition = list[0].letter;
            address = address + list[0].letter;
          }
          if (list[0].addition != null) {
            singleInvoiceAddition += list[0].addition;
            address = address + list[0].addition;
          }
          if(singleInvoiceAddition != ""){
            this.form.invoice.address.addition = singleInvoiceAddition
            this.form.invoice.address.additionState = true
          } else {
            this.form.invoice.address.addition = "";
          }

          address = address + ", " + list[0].postcode + " " + list[0].city;

          this.form.invoice.address.city = list[0].city;
          this.form.invoice.address.cityState == true;
          this.foundInvoiceAddress = address;
          this.validatePostCode();
          // this.validateInvoiceHouseNumberAndAddition();
          this.validateInvoiceHouseNumber();
          this.form.invoice.address.house_numberState = true;
        } else if (list.length == 0) {
          this.foundInvoiceAddresses = [];
          this.foundInvoiceAddress = '';
          this.form.invoice.address.house_numberError = this.$t("postcode_not_found");
          this.form.invoice.address.house_numberState = false;
          this.form.invoice.address.addition = '';
          this.form.invoice.address.additionState = false;
        } else if (list.length > 1) {
          if(this.foundInvoiceAddresses.length > 0){
            this.foundInvoiceAddresses = [];
          }
          this.form.invoice.address.house_numberError = '';

          list.forEach(function (item) {
            let address = item.street + " " + item.number;
            if (item.letter != null) {
              address = address + " "  + item.letter;
            }
            if (item.addition != null) {
                address =
                  address + (item.letter == null ? " " : "") + item.addition;
            }
            address = address + ", " + item.postcode + " " + item.city;
            item.text = address;
            this.foundInvoiceAddresses.push(item);
          }, this);
          // Sort foundInvoiceAddresses based on letter and addition
          this.foundInvoiceAddresses.sort(function (a, b) {
            // Handle null values for letter (treat null as empty string)
            const aLetter = a.letter || '';
            const bLetter = b.letter || '';
            const letterCompare = aLetter.localeCompare(bLetter);
            if (letterCompare !== 0) {
              return letterCompare;
            }
            // Handle null values for addition (treat null as 0)
            const aAddition = parseInt(a.addition) || 0;
            const bAddition = parseInt(b.addition) || 0;

            // If letter is the same, sort by addition (numeric order)
            return aAddition - bAddition;
          });

          if(this.selectedInvoiceAddress === null){
            this.form.invoice.address.house_numberState = null;
          } else {
          let additionInvoice = "";
            if(this.foundInvoiceAddresses[this.selectedInvoiceAddress] != undefined){
              if (this.foundInvoiceAddresses[this.selectedInvoiceAddress].letter != null) {
                additionInvoice = this.foundInvoiceAddresses[this.selectedInvoiceAddress].letter;
              }
              if (this.foundInvoiceAddresses[this.selectedInvoiceAddress].addition != null) {
                additionInvoice += this.foundInvoiceAddresses[this.selectedInvoiceAddress].letter !== null ? (!isNaN(this.foundInvoiceAddresses[this.selectedInvoiceAddress].letter) && !isNaN(this.foundInvoiceAddresses[this.selectedInvoiceAddress].addition) ? "-" : "") + this.foundInvoiceAddresses[this.selectedInvoiceAddress].addition : this.foundInvoiceAddresses[this.selectedInvoiceAddress].addition;
              }
              let houseInvoiceNumber = `${this.foundInvoiceAddresses[this.selectedInvoiceAddress].number}`;
              if(this.form.invoice.address.house_number != houseInvoiceNumber){
                this.selectedInvoiceAddress = null;
                this.form.invoice.address.house_numberState = null
                this.form.invoice.address.additionState = null
              }
              if(this.form.invoice.address.addition == '' 
              || this.form.invoice.address.addition == null 
              || this.form.invoice.address.addition != additionInvoice){
                this.form.invoice.address.additionState = null
                this.selectedInvoiceAddress = null;
              }
            } else {
              this.selectedInvoiceAddress = null;
              this.form.invoice.address.house_numberState = null
              this.form.invoice.address.additionState = null
            }
          }
        }
      } else {
        if (this.form.invoice.address.postcode == "") {
          this.form.invoice.address.postcodeState = false;
          this.form.invoice.address.postcodeError =
            this.$t("postcode_required");
        } else {
          this.form.invoice.address.postcodeState = true;
        }
      }
    },
    validateInvoicePostcode() {
      const status = this.$helpers.validatePostcode(this.form.invoice.address.postcode, this.form.invoice.address.country_code);
      this.form.invoice.address.postcodeState = status

      if (status == false) {
        this.form.invoice.address.postcodeError = this.$t("postcode_required")
      }
    },
    async selectAddress(id) {
      if (id) {
        this.$store.dispatch('setLoading', true)//Set Loader
        await this.$store.dispatch("cart/addShippingAddressById", { id: id });
        await this.$store.commit("cart/setShippingAddressId", id);
        this.selectedShippingAddressId = id;
        this.$store.dispatch('setLoading', false)//Unset Loader
        await this.$nextTick();
        let countryCode;
        let getAddress = await this.$store.getters['cart/getShippingAddress'];
        if(getAddress == null){
          getAddress = this.$store.getters['user/getDefaultShippingAddress'];
          countryCode = getAddress.country_code;
        }else{
          countryCode = getAddress.country.code;
        }
        this.form.company = getAddress.company;
        this.form.taxvat = getAddress.vat_id;
        this.form.taxvatState = true;
        this.form.firstname = getAddress.firstname;
        this.form.firstnameState = true;
        this.form.lastname = getAddress.lastname;
        this.form.lastnameState = true;
        this.form.address.postcode = getAddress.postcode;
        this.form.address.postcodeState = true;
        this.form.address.house_number = getAddress.street[1];
        this.form.address.house_numberState = true;
        this.form.address.addition = getAddress.street[2];
        this.form.address.city = getAddress.city;
        this.form.address.cityState = true;
        this.form.address.streetDisplay = getAddress.street[0];
        this.form.address.streetDisplayState = true;
        this.form.address.country_code = countryCode;
        this.form.address.country_codeState = true;
        this.form.address.telephone = getAddress.telephone;
        this.form.address.telephoneState = true;
        this.newAddress = false;
        if(getAddress.company == null){
          this.isCompany = false;
        }
        if(this.form.address.default_billing == true){
          await this.$store.dispatch("cart/addBillingAddressById", { id: id });
        }
      }
    },
    async selectInvoiceAddress(id) {
      if (id) {
        this.$store.dispatch('setLoading', true)//Set Loader
        await this.$store.dispatch("cart/addBillingAddressById", { id: id });
        await this.$store.commit("cart/setBillingAddressId", id);
        this.selectedBillingAddressId = id;
        this.$store.dispatch('setLoading', false)//Unset Loader
        let countryInvoiceCode;
        let getInvoiceAddress = await this.$store.getters['cart/getBillingAddress'];
        if(getInvoiceAddress == null){
          getInvoiceAddress = this.$store.getters['user/getDefaultBillingAddress'];
          countryInvoiceCode = getInvoiceAddress.country_code;
        }else{
          countryInvoiceCode = getInvoiceAddress.country.code;
        }
        this.form.invoice.company = getInvoiceAddress.company;
        this.form.invoice.taxvat = getInvoiceAddress.vat_id;
        this.form.invoice.firstname = getInvoiceAddress.firstname;
        this.form.invoice.firstnameState = true;
        this.form.invoice.lastname = getInvoiceAddress.lastname;
        this.form.invoice.lastnameState = true;
        this.form.invoice.address.postcode = getInvoiceAddress.postcode;
        this.form.invoice.address.postcodeState = true;
        this.form.invoice.address.house_number = getInvoiceAddress.street[1];
        this.form.invoice.address.house_numberState = true;
        this.form.invoice.address.addition = getInvoiceAddress.street[2];
        this.form.invoice.address.streetDisplay = getInvoiceAddress.street[0];
        this.form.invoice.address.streetDisplayState = true;
        this.form.invoice.address.city = getInvoiceAddress.city;
        this.form.invoice.address.cityState = true;
        this.form.invoice.address.country_code = countryInvoiceCode;
        this.form.invoice.address.country_codeState = true;
        this.form.invoice.address.telephone = getInvoiceAddress.telephone;
        this.form.invoice.address.telephoneState = true;
        if(getInvoiceAddress.company == null){
          this.isInvoiceCompany = false;
        }
        this.newInvoiceAddress = false;
      }
    },
    selectedShippingAddress() {
      const sa = this.$store.getters["cart/getShippingAddress"];
      if (sa != null && sa.id != null) {
        this.selectedShippingAddressId = sa.id;
        this.selectAddress(sa.id)
        this.newAddress = false
        return;
      }
      this.newAddress = true
      return;
    },
    selectedBillingAddress() {
      const sa = this.$store.getters["cart/getBillingAddress"];
      if (sa != null) {
        this.selectedBillingAddressId = sa.id;
        this.selectInvoiceAddress(sa.id);
        this.newInvoiceAddress = false;
        return sa.id;
      }
      this.newInvoiceAddress = true;
      this.selectedBillingAddressId = 0;
      return 0;
    },
  },
  computed: {
    shippingAddress() {
      return this.$store.getters["cart/getShippingAddress"];
    },
    countries() {
      return this.$store.getters["user/getCountries"];
    },
    country() {
      return this.form.address.country_code;
    },
    address() {
      if (!this.showFullAddress) {
        return `${this.form.company}|${this.form.taxvat}|${this.form.firstname}|${this.form.lastname}|${this.form.address.country_code}|${this.form.address.city}|${this.form.address.streetDisplay}|${this.form.address.house_number}|${this.form.address.telephone}||${this.form.address.city}`;
      } else {
        return `${this.form.company}|${this.form.taxvat}|${this.form.firstname}|${this.form.lastname}|${this.form.address.country_code}|${this.form.address.city}|${this.form.address.streetDisplay}|${this.form.address.house_number}|${this.form.address.telephone}|${this.form.address.postcode}|${this.form.address.city}`;
      }
    },
    invoiceAddress() {
      if (!this.showFullInvoiceAddress) {
        return `${this.form.invoice.company}|${this.form.invoice.taxvat}|${this.form.invoice.firstname}|${this.form.invoice.lastname}|${this.form.invoice.address.country_code}|${this.form.invoice.address.city}|${this.form.invoice.address.streetDisplay}|${this.form.invoice.address.house_number}|${this.form.invoice.address.addition}|${this.form.invoice.address.telephone}||${this.form.invoice.address.city}`;
      } else {
        return `${this.form.invoice.company}|${this.form.invoice.taxvat}|${this.form.invoice.firstname}|${this.form.invoice.lastname}|${this.form.invoice.address.country_code}|${this.form.invoice.address.city}|${this.form.invoice.address.streetDisplay}|${this.form.invoice.address.house_number}|${this.form.invoice.address.addition}|${this.form.invoice.address.telephone}|${this.form.invoice.address.postcode}|${this.form.invoice.address.city}`;
      }
    },
    invoiceCountry() {
      return this.form.invoice.address.country_code;
    },
    postcodeHouseNumber() {
      return `${this.form.address.postcode}|${this.form.address.house_number}|${this.form.address.addition}`;
    },
    invoicePostcodeHouseNumber() {
      return `${this.form.invoice.address.postcode}|${this.form.invoice.address.house_number}|${this.form.invoice.address.addition}`;
    },
    vatNumber() {
      return this.form.taxvat;
    },
    invoiceVatNumber() {
      return this.form.invoice.taxvat;
    },
    Email() {
      return this.form.email;
    },
    Password() {
      return this.form.password;
    },
    PasswordConfirm() {
      return this.form.password_confirm;
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    addresses() {
      const user = this.$store.getters["user/getCurrentUser"];
      return user.addresses;
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
  },
  watch: {
    country() {
      if (config.postcode_validation.enabled) {
        if (!config.postcode_validation.countries.includes(this.country)) {
          this.showFullAddress = true;
        } else {
          this.showFullAddress = false;
        }
      } else {
        this.showFullAddress = true;
      }
      if (this.form.address.postcode != "") {
        this.validatePostcodeHouseNumber();
      }
    },
    address() {
      if (this.form.addressTimer !== null) {
        clearTimeout(this.form.addressTimer);
      }
      this.form.addressTimer = setTimeout(
        function (scope) {
          scope.validateAddress();
        },
        1000,
        this
      );
    },
    invoiceAddress() {
      if (this.form.invoiceAddressTimer !== null) {
        clearTimeout(this.form.invoiceAddressTimer);
      }
      this.form.invoiceAddressTimer = setTimeout(
        function (scope) {
          scope.validateInvoiceAddress();
        },
        1000,
        this
      );
    },
    invoiceCountry() {
      if (config.postcode_validation.enabled) {
        if (
          !config.postcode_validation.countries.includes(this.invoiceCountry)
        ) {
          this.showFullInvoiceAddress = true;
        } else {
          this.showFullInvoiceAddress = false;
        }
      } else {
        this.showFullInvoiceAddress = true;
      }
      if (this.form.invoice.address.postcode != "") {
        this.validateInvoicePostcodeHouseNumber();
      }
    },
    vatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.taxvatTimer !== null) {
          clearTimeout(this.form.taxvatTimer);
        }
        this.form.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateVAT();
          },
          1000,
          this
        );
      }
    },
    invoiceVatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.invoice.taxvatTimer !== null) {
          clearTimeout(this.form.invoice.taxvatTimer);
        }
        this.form.invoice.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateInvoiceVAT();
          },
          1000,
          this
        );
      }
    },
    postcodeHouseNumber(newVal) {
      if (this.showFullAddress == false) {
        if (this.typeTimer !== null) {
          clearTimeout(this.typeTimer);
        }
        if (this.foundAddress != "") {
          this.foundAddress = "";
        }
        // if (this.foundAddresses.length > 0) {
        //   this.foundAddresses = [];
        // }
        const [newPostcode, newHouseNumber] = newVal.split("|");
        if (newPostcode != "" && newHouseNumber != "") {
          this.typeTimer = setTimeout(
            function (scope) {
              scope.validatePostcodeHouseNumber();
            },
            2000,
            this
          );
        }
      }
    },
    invoicePostcodeHouseNumber(newVal) {
      if (this.showFullInvoiceAddress == false) {
        if (this.typeTimer !== null) {
          clearTimeout(this.typeTimer);
        }
        if (this.foundInvoiceAddress != "") {
          this.foundInvoiceAddress = "";
        }
        // if (this.foundInvoiceAddresses.length > 0) {
        //   this.foundInvoiceAddresses = [];
        // }
        const [newPostcode, newHouseNumber] = newVal.split("|");
        if (newPostcode != "" && newHouseNumber != "") {
          this.typeTimer = setTimeout(
            function (scope) {
              scope.validateInvoicePostcodeHouseNumber();
            },
            2000,
            this
          );
        }
      }
    },
    async selectedAddress(newVal) {
      if (newVal !== null) {
        this.form.address.streetDisplay = this.foundAddresses[newVal].street;
        this.form.address.streetDisplayState = true;
        let addition = "";
        if (this.foundAddresses[newVal].letter != null) {
          addition = this.foundAddresses[newVal].letter;
        }
        if (this.foundAddresses[newVal].addition != null) {
          addition += this.foundAddresses[newVal].letter !== null ? (!isNaN(this.foundAddresses[newVal].letter) && !isNaN(this.foundAddresses[newVal].addition) ? "-" : "") + this.foundAddresses[newVal].addition : this.foundAddresses[newVal].addition;
        }
        // this.form.address.house_number_and_addition = `${this.foundAddresses[newVal].number} ${addition}`;
        this.form.address.house_number = `${this.foundAddresses[newVal].number}`;
        this.form.address.addition = `${addition}`;
        this.form.address.house_numberState = true;
        this.form.address.additionState = true;
        if(this.form.address.addition == '' || this.form.address.addition == null){
          this.form.address.additionState = null;
        }
        this.form.address.city = this.foundAddresses[newVal].city;
        this.form.address.cityState = true;
        this.form.address.additionDisabled = true;
        await this.validateAddress();
      }
    },
    async selectedInvoiceAddress(newVal) {
      this.form.invoice.address.streetDisplay = this.foundInvoiceAddresses[newVal].street;
      this.form.invoice.address.streetDisplayState = true;
      let additionInvoice = "";
      if (this.foundInvoiceAddresses[newVal].letter != null) {
        additionInvoice =
          this.foundInvoiceAddresses[newVal].letter;
      } 
      if (this.foundInvoiceAddresses[newVal].addition != null) {
        additionInvoice += this.foundInvoiceAddresses[newVal].letter !== null ? (!isNaN(this.foundInvoiceAddresses[newVal].letter) && !isNaN(this.foundInvoiceAddresses[newVal].addition) ? "-" : "") + this.foundInvoiceAddresses[newVal].addition : this.foundInvoiceAddresses[newVal].addition;
      }
      // this.form.invoice.address.house_number_and_addition = `${this.foundInvoiceAddresses[newVal].number} ${additionInvoice}`;
      this.form.invoice.address.house_number = `${this.foundInvoiceAddresses[newVal].number}`;
      this.form.invoice.address.addition = `${additionInvoice}`;
      this.form.invoice.address.house_numberState = true;
      this.form.invoice.address.additionState = true;
      if(this.form.invoice.address.addition == '' || this.form.invoice.address.addition == null){
          this.form.invoice.address.additionState = null;
      }
      this.form.invoice.address.city = this.foundInvoiceAddresses[newVal].city;
      this.form.invoice.address.cityState = true;
      await this.validateInvoicePostcode();
    },
    Email() {
      if (this.form.emailTimer !== null) {
        clearTimeout(this.form.emailTimer);
      }
      this.form.emailTimer = setTimeout(
        function (scope) {
          scope.validateEmail();
        },
        1000,
        this
      );
    },
    showFullAddress() {
      if (this.showFullAddress) {

        if (this.form.address.postcodeState) {
          this.validatePostCode();
        }
        if (this.form.address.house_numberState) {
          // this.validateHouseNumberAndAddition();
          this.validateHouseNumber();
        }
        if (this.form.address.cityState) {
          this.validateCity();
        }
      } else {
        this.validatePostcodeHouseNumber();
      }
      if (this.form.address.telephoneState) {
        this.validatePhone();
      }
      if (this.addressState.isOk) {
        this.validateAddress();
      }
    },
    showFullInvoiceAddress() {
      // if (this.localStorageData.invoiceAddress.streetDisplayState == true) {
      this.validateInvoiceCity();
      if (this.showFullInvoiceAddress) {
        this.validateInvoicePostcode();
        // this.validateInvoiceHouseNumberAndAddition();
        this.validateInvoiceHouseNumber();
        this.validateInvoiceCity();
      } else {
        this.validateInvoicePostcodeHouseNumber();
      }
      this.validateInvoicePhone();
      this.validateInvoiceAddress();
      // }
    },
    newAddress() {
      if (this.newAddress == true) {
        this.selectedShippingAddressId = 0
        this.form.firstname = "";
        this.form.firstnameState = null;
        this.form.lastname = "";
        this.form.lastnameState = null;
        this.form.address.postcode = "";
        this.form.address.postcodeState = null;
        this.form.address.telephone = "";
        this.form.address.telephoneState = null;
        this.checkFieldsLoggedin();
      } else {
        this.$store.commit("cart/setStoreValidation", {
          address: [],
        });
        this.selectedShippingAddress()
      }
    },
    newInvoiceAddress() {
      if (this.newInvoiceAddress == true) {
        this.selectedBillingAddressId = 0
        this.checkFieldsLoggedin();
      } else {
        this.$store.commit("cart/setStoreValidation", {
          address: [],
        });
        this.selectedBillingAddress()
      }
    },
    isCompany(){
      this.checkFieldsLoggedin();
    },
    "user": function () {
      if(this.user.default_shipping != this.user.default_billing){
        this.form.address.default_billing = false;
      }
      this.selectAddress(this.user.default_shipping);
      this.selectInvoiceAddress(this.user.default_billing);
    },
    "form.address.default_billing"(newValue) {
      if (this.shippingAddress?.id && newValue == true) {
        this.selectInvoiceAddress(this.shippingAddress.id)
      }
    }
  },
  created() {
    const cartShippingAddressId = this.$store.getters["cart/getShippingAddressId"];
    const cartBillingAddressId = this.$store.getters["cart/getBillingAddressId"];
    const { default_shipping, default_billing } = this.user;
    const addressLength = this.addresses?.length;
    var addressId = null
    var setShippingId = null
    var setBillingId = null
    var isSameAddress = false
    if (addressLength > 0) {
      addressId = this.addresses[0].id
    }

    if (cartShippingAddressId && cartBillingAddressId) {
      setShippingId = cartShippingAddressId;
      setBillingId = cartBillingAddressId;
    }
    else {
      setShippingId = default_shipping ? default_shipping : (addressLength > 0 ? addressId : null);
      setBillingId = default_billing ?? setShippingId;
    }

    if (setShippingId && setBillingId) {
      isSameAddress = setShippingId == setBillingId;
      this.form.address.default_billing = isSameAddress;
      this.selectAddress(setShippingId)
      this.selectInvoiceAddress(setBillingId);
    } else {
      this.form.address.default_billing = true;
      if (addressLength == 0) {
        this.newAddress = true
      }
    }
  },
  data() {
    return {
      newAddress: false,
      selectedShippingAddressId: null,
      selectedBillingAddressId: null,
      newInvoiceAddress: false,
      isCompany: false,
      isInvoiceCompany: false,
      isValidVatNumber: false,
      isValidInvoiceVatNumber: false,
      showFullAddress: false,
      showFullInvoiceAddress: false,
      showVatMessage: "",
      showVatCheck: false,
      addressTimer: null,
      invoiceAddressTimer: null,
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      showManual: true,
      typeTimer: null,
      foundAddresses: [],
      foundAddress: "",
      foundInvoiceAddresses: [],
      foundInvoiceAddress: "",
      emailExists: false,
      selectedAddress: null,
      selectedInvoiceAddress: null,
      addressState: {},
      prefixOptions: [
        { value: this.$t('male'), text: this.$t('male') },
        { value: this.$t('female'), text: this.$t('female') },
        { value: "", text: this.$t('not_specified') },
      ],
      form: {
        email: "",
        emailState: null,
        emailError: "",
        emailTimer: null,
        password: "",
        passwordState: null,
        passwordError: "",
        passwordTimer: null,
        password_confirm: "",
        password_confirmState: null,
        password_confirmError: "",
        password_confirmTimer: null,
        company: "",
        companyState: null,
        companyError: "",
        companyTimer: null,
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        firstnameTimer: null,
        prefix: "",
        prefixState: true,
        prefixError: "",
        lastname: "",
        lastnameState: null,
        lastnameError: "",
        lastnameTimer: null,
        // coc_number: "",
        // coc_numberState: null,
        // coc_numberError: "",
        // coc_numberTimer: null,
        taxvat: "",
        taxvatState: null,
        taxvatError: "",
        taxvatTimer: null,
        address: {
          country_code: "",
          country_codeState: null,
          postcode: "",
          postcodeState: null,
          postcodeError: "",
          postcodeTimer: null,

          street: "",
          streetDisplay: "",
          streetDisplayState: null,
          streetDisplayError: "",
          streetDisplayTimer: null,

          house_number: "",
          house_numberState: null,
          house_numberError: "",
          house_numberTimer: null,

          house_number_and_addition: "",
          house_number_and_additionState: null,
          house_number_and_additionError: "",
          house_number_and_additionTimer: null,

          addition: "",
          additionState: null,
          city: "",
          cityState: null,
          cityError: "",
          cityTimer: null,

          telephone: "",
          telephoneState: null,
          telephoneError: "",
          telephoneTimer: null,
          default_billing: false,
          default_shipping: true,
        },
        invoice: {

          company: "",
          companyState: null,
          companyError: "",
          companyTimer: null,

          prefix: "",
          prefixState: true,
          prefixError: "",

          firstname: "",
          firstnameState: null,
          firstnameError: "",
          firstnameTimer: null,
          lastname: "",
          lastnameState: null,
          lastnameError: "",
          lastnameTimer: null,
          // coc_number: "",
          // coc_numberState: null,
          // coc_numberError: "",
          // coc_numberTimer: null,
          taxvat: "",
          taxvatState: null,
          taxvatError: "",
          taxvatTimer: null,
          address: {
            country_code: "",
            country_codeState: null,
            postcode: "",
            postcodeState: null,
            postcodeError: "",
            postcodeTimer: null,

            street: "",
            streetDisplay: "",
            streetDisplayState: null,
            streetDisplayError: "",
            streetDisplayTimer: null,

            house_number: "",
            house_numberState: null,
            house_numberError: "",
            house_numberTimer: null,

            house_number_and_addition: "",
            house_number_and_additionState: null,
            house_number_and_additionError: "",
            house_number_and_additionTimer: null,

            addition: "",
            additionState: null,
            city: "",
            cityState: null,
            cityError: "",
            cityTimer: null,

            telephone: "",
            telephoneState: null,
            telephoneError: "",
            telephoneTimer: null,
            default_billing: true,
            default_shipping: true,
          },
        },
        checkbox2: false,
        manual: false,
      },
    };
  },
};
</script>
