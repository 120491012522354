<template>
  <AppLayout wrapperClass="checkout">
    <Breadcrumbs />
    <section class="checkout-details pt-3">

      <b-container>
        <div class="row">
          <b-col lg="4" class="contact-information">
            <NotLoggedInCheckoutDetails v-if="!isLoggedIn" ref="NotLoggedInCheckoutDetails"
              :updateLocalStorage="updateLocalStorage" />
            <LoggedInCheckoutDetails v-if="isLoggedIn" ref="loggedInCheckoutDetails" />
          </b-col>
          <b-col lg="4" class="pt-20 pt-lg-0">
            <div class="group">
              <div class="group-title d-flex align-items-center mb-5">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD1UlEQVR4nO2cy2oUQRRAT0TjY58ENYbEuNKlD3yAIOI/ZOEHuNGAIYrGbNxE4xOjKC4VXBhxr8sganYK/oJRUVRwxmBMLKlwA0Gnu6t7qrurZ+6BS2CYrrlzUrldj66AoiiKoiiKoiiKoiiKoiiK0oANwDAwC9QAE3DUgNfAKWB9lX6bvcDbAASaDPFG8q9ET66qZLNKdvA9ezgAUcZDnCRwZgOQZDzEKwLnRwCSjIew3yNoTAtF0JQtx6jo8sUZ7dH/S1gEZoARYD/QDayT6JbXRuQ99r0qOqWEn8AlkelKN3BZrtUe7SBhOmFWtiZB+DbgiZaOaMFLwHmgI0LgRuA28F1iSl5rhG1jTNo0gcVXGZ+fkBl0U6T9cCtkKKHNmw2uu55wzVCgsldP87cUKdr25CQ+Nrjug8N1YwEIjYt3QGcRoqdjyoVLm0l0eK7ZecQoGXH9gHm5eTXTpgtbgXoAQqPiU9aVQtcPmPDQpiuTAQiNi+NkwKXhxZTjZNOk6J4Sboy/gDPN3vCySFkdM57aTMOLgkWfJWdckjjtqc00jBYsejM545LEfk9tpuFQwaJzxyWJLk9t3gf6U9TpthOddpBuYmIBeADsSGjDDqFUdAJfHCRZ4VdkGVVFZywd11L0RrvE2oi2LB0HUrbZCdyQXpvU9lxEG215M7Q7I1nolxvgQgbRRQ/vciePCUsa4ROBTFhyJ48peJzwu7Jk+l4krw1kCp47ronYPb6iuFqw5KBE22XSvgLy6c1h07ZSoo0syrss/GfFtv20BMnBiTay3ZQX4yVJLuQXlsfmbBZC35wtXLSNPzKj81FGOmQtuBUkexe9uma77iM2oq/Emlwp0SujkUkZ+7rSIwtK8wGIqYzolViS2ZydOh8UmZ0SPfLaqLynVcpEKaI1UNFGezQt95egpQMVTdm9UHs05YvT0kH5UrVGo6Jpqx69Cbgj50t8HA0+BwzIMxoDciqgFsCXLiP/b3J+Z/nMzj2PSe6OGDjuqYDsPPO3jr1tFdmeEMeFAGSWlX/dp2j7ZxbHYAAyy8q/7rN0dAb2oKIJKH/7WMVyoZ6Swh1qjzAFRB7520Ogt2IOsMbyPOOZw/GI655RLJXJP+qmYO/KeyOu2RdzjM3lUGhb5j8Q868favJFtkvNG5SeUI94/++CHsSpbP6PPNXFh3km2Qr5d8mp0WaS/JxyE7dt8z/SxBi8DhwuIslWyf+Y4xkV809POEoYVCr/Xnl4JumxgSXgsRykD4nK5b8LuCj/pWVOnui3P1/K6zsJm6rnryiKoiiKoiiKoiiKQlH8BdjsQhO1DSMqAAAAAElFTkSuQmCC"
                  alt="delivery" width="45" />
                <span>{{ $t("shipping_method") }}</span>
              </div>
              <div v-if="deliveryOptions.length == 0">
                {{ $t("fill_address_information") }}
              </div>
              <div v-if="deliveryOptions.length !== 0">
                <ul class="delivery-options-list list-unstyled pl-0 m-0">
                  <li v-for="(deliveryOption, index) of deliveryOptions" :key="`ship-${index}`">
                    <b-form-radio class="pl-30" v-model="selectedShippingMethod" name="deliveryOption"
                      :value="`${deliveryOption.carrier_code}|${deliveryOption.method_code}`">
                      <div class="d-flex">
                        <span class="mx-6 delivery-price d-block">{{
                          formatCurrency(deliveryOption.price_incl_tax.value)
                          }}&nbsp;</span>
                        <span class="d-block mx-6">{{ deliveryOption.carrier_title }} ({{
                          deliveryOption.method_title
                          }})</span>
                      </div>
                    </b-form-radio>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="showPickupLocation">
              <span class="d-block mb-15">{{ $t("select_store") }}</span>
              <b-form-group class="select--custom">
                <b-form-select v-model="pickupLocation" :options="pickupLocations" class="mb-3"
                  value-field="pickup_location_code" text-field="name" id="pickup-dropdown">
                  <template #first>
                    <b-form-select-option :value="null">{{
                      $t("select_store")
                      }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <div>
              <div class="address-list store-address" v-if="showPickupLocation && selectedPickupLocation">
                <div class="address-list-wrap">
                  <div class="address-item" v-if="selectedAddress != null">
                    <label>{{ pickupLocationName }}</label>
                    <div>{{ selectedAddress.street[0] }}</div>
                    <div>
                      {{ selectedAddress.postcode }} {{ selectedAddress.city }}
                    </div>
                    <div v-if="selectedAddress.country">
                      {{ selectedAddress.countryName }}
                    </div>
                    <div>
                      T:<a :href="`tel:` + pickupLocationPhone">
                        {{ pickupLocationPhone }}</a>
                    </div>
                    <div v-if="selectedAddress.vat_id != null">
                      VAT:{{ selectedAddress.vat_id }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="group">
              <div class="group-title d-flex align-items-center mb-5">

                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAABx0lEQVR4nO2cy07DMBQFZ0X5hBL4RB470i/ksQI+BcGmKyNLEQsk2thVc+NkRvLOuqpGJydKGhlERERERERERERE5AiXwAPwCnwDyUUaXLwA98Dm1BTdAB+K5Vi43gdX1UlWMkWyq5Kd68KaoMjBXY3o3MmKpsjBc43oL0VTGrTsrBjTTJUDRTNNeBSNollSTZloFE10Ck008eKsDuKl2tEomujEmWjiJVkdrLyj98Aj0B2Y0wH9sDdaQrOis8Cx9DOQ0Kzoq4J52xlIaFb0OWamxpeiUTTRKTTRxIuzOoiXakezMNGHHlT+cj2DtK3igWU3AwnNit4Psn0E57yiXUyTaBeKTkvqaBeKTiaaRV8JVgeKJjqFJpp4cVYH8VLtaBRNdOKa/67jv+839iP2tDgz/DVpX7EnNTgz/LuObcWe1ODMSUV3I/5J6UbsaXFmeHXsKvakBmeG/cNy6CbTF964Wpg5qWgXik5BQTDRKJolVZCJRtGsOtEejEKx5M8a0R71wzRH/dzP4DJMja3bGtGb4Yix6B+fGllvwAWV5EPzlM0oyfkF1ElshvPccv94g+RXbnbxNNRFdZJFREREREREREREWAc/ev7rmNg66wAAAAAASUVORK5CYII="
                  alt="payment" width="45" />

                <span class="d-block">{{ $t("payment_method") }}</span>
              </div>
              <div v-if="deliveryOptions.length == 0 || shippingMethod == null">
                {{ $t("fill_shipping_options") }}
              </div>
              <div v-if="deliveryOptions.length != 0 || shippingMethod != null">
                <ul class="payment-options-list list-unstyled p-0 m-0">
                  <li v-for="(paymentOption, index) in paymentOptions" :key="`pay-${index}`">
                    <b-form-radio class="pl-30" name="paymentOption" :value="paymentOption.code"
                      v-on:change="checkAllSubOptions(paymentOption.code)" v-model="selectedPaymentMethod">
                      <div>
                        <div class="d-flex align-items-center">
                          <div class="payment-option-img">
                            <img :src="`${app_url}/media/graphql/icons/50x32/${paymentOption.code}.png`"
                              :alt="paymentOption.code" />
                          </div>
                          <h5>{{ paymentOption.label }}</h5>
                        </div>
                        <span class="instructions" :v-if="paymentOption.instructions"
                          v-html="paymentOption.instructions"></span>
                      </div>
                    </b-form-radio>
                    <div :class="showPaymentSuboptions"
                      v-for="(paymentSubOption, index) in paymentOption.additional_fields" :key="`pso-${index}`">
                      <div v-if="paymentSubOption.type == 'select'" class="pt-4">
                        <span class="d-block mb-15">{{ paymentSubOption.label }}</span>
                        <b-form-group class="select--custom">
                          <b-form-select :options="paymentSubOption.options" text-field="label"
                            :value="paymentMethodOptions[paymentOption.code][paymentSubOption.code]"
                            v-on:change="setPaymentMethodOptions(paymentOption.code, paymentSubOption.code, $event)"
                            v-model="selectedIssuer">
                            <template #first>
                              <b-form-select-option :value="null">{{ $t("select_bank") }}</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </div>
                    </div>
                    <div :class="isKlarnaSelected" v-if="paymentOption.code == 'multisafepay_klarna'">
                      Je moet minimaal 18+ zijn om deze dienst te gebruiken. Als
                      je op tijd betaalt, voorkom je extra kosten.
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
          <b-col lg="4" class="pt-30 pt-lg-0">
            <div class="order-overview-wrap py-10 px-20">
              <span class="group-title d-block">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAACtUlEQVR4nO2ay2oUQRSGfzSULkW8RB9GzUqyyNKFS3GlZp/xCYxrTZ5DRIIbr/goRgnusnF6daSgBgZJmK5OnUtP/x8cCEMP1efjdOXv7gEIIWStuQJgH8DvUvvlM9KQBOA9APmvPgK4StO6koWy7SQLZdtJFsq2kyyUbSdZKLs/Oa4drRB51PMYRr8LTPIiztUcS5YYIo6yK0kXmE7KNpC8gLJXkBrus5RtKCbxH6SdEMo2FDF52cnw0p6s7OTQ+ORkJ8eGJyM7QqMpwDmoEqnBFOhcCCGEEEKcyJHoNYBfK2IRCzguP1HLzqrJX6REVDnIzqrhJKN60PKPL6vhNGOQA4qGzfBQNCga67RNcaJB0Zj0RB8HOGkZWf0cIpo3LKgW/WqI6FRkc7Khewu+zIsAl6VU1hzAHoDNUvnvTmGd52jI1gglb5/Rx0xhrQctRd8KIE96Vp7anXP6uK2w3k005s/IJWfuNl7vBAp8DSBSBmwXy7xsvOYXDdGHI5e8XY5rue6BhujdAEJlwHaReQjgb/TEETl5dI6Sc93XEB0teXTOklUSh3by6M64uZgH3JPVE4d28tirEBVBslri0E4emz0v/QjbxaLeQpFdY9HL4iJJzvUMimglj1mPrSDCdqGeOLSTx7yHyEiSVROHRfLYGXA+ltuFSeJY8E2xga5StofkXJ9hgPYzj66nbC/J6onD8plHt0K2p2T1xGH9zKM7R7a35Fz3LERbPvOYl+h3p9TMIV24JI4xvW2RMScOi+QhwcskcYzhbYso1xtL0VHftsi6JI7Ib1tknRJH1LctYlg3YMwUk8cJHPgQoHExrnceoh8HaFyM65GH6EsTy9OfSs8uXAfwI4AEUa48UNfgzAaApwC+AzgNIEUa1WkR/ATAZW/JhBBCCCGEEEIIIYQQgnHzD6elXjI/9tsyAAAAAElFTkSuQmCC"
                  width="45">
                {{ $t("order_overview") }}</span>
              <div class="cart-details pb-15">
                <b-link href="#" class="d-block mb-10 group-subtitle border-0 " v-html="getItemsInCart"></b-link>
                <div id="cart-details-collapse" class="bg-white">
                  <div class="cart-items d-flex align-items-top py-20" v-for="cartItem of cartItems" :key="cartItem.id">
                    <div class="img">
                      <img :src="cartItem.product.image.small" :alt="cartItem.product.name" />
                    </div>
                    <div class="details">
                      <span class="name d-block mb-20">{{ cartItem.product.name }}</span>
                      <div v-if="typeof cartItem.configurable_options != 'undefined'">
                        <span v-for="(option, index) of cartItem.configurable_options" :key="index"
                          class="product-options d-block">
                          <span class="product-options-title">{{ option.option_label }}:&nbsp;</span>
                          <span class="product-options-value">{{ option.value_label }}</span>
                        </span>
                      </div>
                      <div class="product-info">
                        <span class="number"> <strong> Produktnummer : </strong> {{ cartItem.product.sku }} </span>
                        <span class="inhalt"> <strong>Inhalt :</strong> {{ cartItem.product.inhalt }}</span>
                        <span class="gramm" v-html="formateofGramm(cartItem.product)"></span>
                        <span class="vat-text">{{ $t("price_incl_vat_excl_shipping") }}</span>
                      </div>
                      <div class="quantity-box">
                        <div class="quantity-input d-flex">
                          <b-link href="#" class="step-down-btn" @click="quantityDown(cartItem.id, cartItem.quantity)">
                            -
                          </b-link>
                          <input type="number" min="1" :value="cartItem.quantity" name="quantity" ref="inputQuantity"
                            disabled />
                          <b-link href="#" class="step-up-btn" @click="quantityUp(cartItem.id, cartItem.quantity)"> +
                          </b-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="order-overview">
                <h4 class="pb-15 d-block">{{ $t("order_overview") }}</h4>
                <ul class="cost-list list-unstyled pl-0 m-0">
                  <li class="d-flex justify-content-between">
                    <span class="d-block mr-20">{{ $t("subtotal") }}</span>
                    <span class="d-block">{{ formatCurrency(cartSubTotalPrice) }}</span>
                  </li>

                  <li v-if="shippingMethod != null" class="d-flex justify-content-between">
                    <span class="d-block mr-20">
                      {{ $t("shipping_method_title") }}</span>
                    <span class="d-block" v-if="shippingMethod.amount_including_tax">
                      + {{ formatCurrency(shippingMethod.amount_including_tax.value) }}</span>
                  </li>
                  <li v-for="(price, index) in cartTotalDiscounts" :key="`price-${index}`"
                    class="d-flex justify-content-between">
                    <span class="d-block mr-20">{{ price.label }}</span>
                    <span class="d-block">- {{ formatCurrency(price.amount.value) }}</span>
                  </li>
                  <li v-for="(price, index) in cartTotalTaxPrices" :key="`tax-${index}`"
                    class="d-flex justify-content-between">
                    <span class="d-block mr-20">{{ price.label }}</span>
                    <span class="d-block" v-if="price">{{ formatCurrency(price.amount.value) }}</span>
                  </li>
                </ul>
                <div class="total-grp d-flex justify-content-between">
                  <span class="total">{{ $t("total") }}</span>
                  <span class="total-price">
                    {{ formatCurrency(cartTotalPrice) }}</span>
                </div>
              </div>
              <div class="coupon pt-15 mb-15">
                <b-link class="d-block" se> {{ $t("coupon_discount") }}</b-link>
                <div class="d-flex">
                  <b-form-input v-validParent v-model="couponCode" type="text"
                    :placeholder="$t('fill_out_discount_code')"></b-form-input>
                  <b-button @click="addCoupon" class="py-3">Hinzufügen</b-button>
                </div>
                <div v-if="appliedCouponCodes && appliedCouponCodes[0]" class="pt-10">
                  {{ $t("applied_coupon_code") }}: {{ appliedCouponCodes[0].code }}
                  <b-link href="#" @click="removeCouponCode()" class="lnr lnr-cross pl-5"></b-link>
                </div>
              </div>
              <div class="practitioner mb-15">
                <h5 class="d-block mb-10"> {{ $t("practitioner") }}</h5>
                <div class="d-flex">
                  <b-form-input v-validParent type="text" v-model="practitionerName" @blur="addComment"
                    :placeholder="$t('fill_out_practitioner')"></b-form-input>
                </div>
              </div>
              <b-form-checkbox class="pl-30" id="newsletter" v-model="SubscribeNewsletter" name="news-letter-subscribe"
                value="yes" unchecked-value="no">{{ $t("subscription_newsletter") }}</b-form-checkbox>
              <span class="terms">Mit der Bestellung erklären Sie sich mit den
                <b-link @click="termsModal()"> Allgemeinen Geschäftsbedingungen </b-link> und unserer
                <b-link @click="privacyModal()"> Datenschutzerklärung </b-link> einverstanden</span>
              <b-button @click="submitForm" v-bind:disabled="buttonClicked" variant="warning"
                class="text-light w-100 checkout-btn">{{ $t("continue_buying") }}</b-button>
            </div>
          </b-col>
        </div>
      </b-container>

      <Model id="termsModal" name="termsModal" :title="generalConditions.title">
        <span v-html="generalConditions.content"></span>
      </Model>
      <Model id="privacyModal" name="privacyModal" :title="privacyPolicy.title">
        <span v-html="privacyPolicy.content"></span>
      </Model>

    </section>
  </AppLayout>
</template>

<script>
import AppLayout from "@/esf_kerkrade_vitanatura/core/components/AppLayout";

import NotLoggedInCheckoutDetails from "@/esf_kerkrade_vitanatura/core/components/NotLoggedInCheckoutDetails";
import LoggedInCheckoutDetails from "@/esf_kerkrade_vitanatura/core/components/LoggedInCheckoutDetails";
import Breadcrumbs from "@/esf_kerkrade_vitanatura/core/components/Breadcrumbs";
import config from "@config";
import Cart from "@storefront/core/modules/cart/mixins";
import Model from "@/esf_kerkrade_vitanatura/core/components/Model";

export default {
  name: "CheckoutPage",
  components: {
    AppLayout,
    NotLoggedInCheckoutDetails,
    LoggedInCheckoutDetails,
    Breadcrumbs,
    Model
  },
  mixins: [Cart],
  computed: {
    generalConditions() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`]("general_conditions")
    },
    privacyPolicy() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`]("privacy_policy")
    },
    getItemsInCart() {
      let items = this.cartCount + " " + this.$t("items_in_shopping_cart")
      items = items.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
      return items;
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    paymentOptions() {
      return this.$store.getters["cart/getPaymentMethods"];
    },
    selectedAddress: {
      get: function () {
        let address = { ...this.$store.getters["cart/getShippingAddress"] };
        return address;
      },
      set: function () {
        //Nothing to do
      },
    },
    deliveryOptions() {
      let options = [...this.$store.getters["cart/getShippingMethods"]];
      if (options.length > 0) {
        const pickIndex = options.findIndex(
          (option) => option.carrier_code === "instore"
        );
        options.push(options.splice(pickIndex, 1)[0]);
      } else {
        return [];
      }
      return options;
    },
    paymentMethodOptions() {
      return this.$store.getters["cart/getPaymentMethodOptions"];
    },
    selectedPaymentMethod: {
      get: function () {
        return this.$store.getters["cart/getPaymentMethod"];
      },
      set: function (method) {
        this.updateLocalStorage({ billing: method });
        this.$store.commit("cart/setPaymentMethod", method);
        if (this.$gtm.enabled()) {
          let prod = [];
          this.cartItems.forEach((element) => {
            let i = {
              id: element.product.sku,
              name: element.product.name,
              quantity: element.quantity,
            };
            prod.push(i);
          });
          window.dataLayer.push({
            event: "eec.checkout",
            ecommerce: {
              checkout: {
                actionField: {
                  step: 2,
                  option: method,
                  action: "checkout",
                },
                products: prod,
              },
            },
          });
        }
      },
    },
    btnDisable() {
      if (this.$store.getters["cart/getShippingAddress"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getBillingAddress"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getShippingMethod"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getPaymentMethod"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getEmail"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getItemsTotalQuantity"] == 0) {
        return true;
      }

      return false;
    },
    pickupLocations() {
      let locations = [...this.$store.getters["cart/getPickupLocations"]];

      let newLocations = [];
      let sortVal = config.pickupSortOptions;
      sortVal.forEach((item) => {
        const index = locations.findIndex(
          (location) =>
            location.pickup_location_code.toLowerCase() == item.toLowerCase()
        );
        if (index > -1) newLocations.push(locations.splice(index, 1)[0]);
      });

      newLocations = [...newLocations, ...locations];

      return newLocations;
    },
    pickupLocationName() {
      if (this.pickupLocation && this.pickupLocation.length > 0) {
        return this.pickupLocations.find(
          (loc) => loc.pickup_location_code === this.pickupLocation
        ).name;
      } else {
        return "";
      }
    },
    pickupLocationPhone() {
      if (this.pickupLocation && this.pickupLocation.length > 0) {
        return this.pickupLocations.find(
          (loc) => loc.pickup_location_code === this.pickupLocation
        ).phone;
      } else {
        return "";
      }
    },
    getSelectedShippingMethod() {
      const shmed = this.$store.getters["cart/getShippingMethod"];
      return shmed;
    },
    shippingMethod: {
      get() {
        return this.$store.getters["cart/getShippingMethod"];
      },
      set(shippingMethod) {
        return shippingMethod;
      },
    },
    appliedCouponCodes() {
      return this.$store.getters["cart/getAppliedCouponCodes"];
    },
    showPaymentSuboptions: {
      get() {
        return this.selectedPaymentMethod == "multisafepay_ideal"
          ? "collapse show"
          : "collapse hidden";
      },
      set() { },
    },
    isKlarnaSelected: {
      get() {
        return this.selectedPaymentMethod == "multisafepay_klarna"
          ? "collapse show"
          : "collapse hidden";
      },
      set() { },
    },
  },
  watch: {
    "user": function () {
      if (this.deliveryOptions.length == 1) {
        this.selectedShippingMethod = `${this.deliveryOptions[0].carrier_code}|${this.deliveryOptions[0].method_code}`
      }
    },
    deliveryOptions(newOptions) {
      if (newOptions.length == 1) {
        this.selectedShippingMethod = `${newOptions[0].carrier_code}|${newOptions[0].method_code}`
      }
    },
    showPickupLocation() {
      this.setShippingMethod();
    },
    async pickupLocation(code) {
      const response = await this.$store.dispatch("cart/setPickupLocation", {
        code,
      });
      if (response) {
        const delOption = this.selectedShippingMethod.split("|");
        await this.$store.dispatch("cart/addShippingMethod", {
          carrier: delOption[0],
          method: delOption[1],
        });
        this.selectedPickupLocation = await this.$store.getters[
          "cart/getShippingAddress"
        ];
        this.updateLocalStorage({
          shipping: this.selectedShippingMethod,
          delivery: code,
        });
        this.$emit("toggleNextDisable", false);
      }
    },
    selectedShippingMethod(method) {
      const [carrier_code, method_code] = method.split("|");

      if (carrier_code == "instore" && method_code == "pickup") {
        this.showPickupLocation = true;
      } else {
        this.updateLocalStorage({
          shipping: this.selectedShippingMethod,
          delivery: null,
        });
        this.showPickupLocation = false;
        this.$store.dispatch("cart/addShippingMethod", {
          carrier: carrier_code,
          method: method_code,
        });
        if (this.$gtm.enabled()) {
          let prod = [];
          this.cartItems.forEach((element) => {
            let i = {
              id: element.product.sku,
              name: element.product.name,
              quantity: element.quantity,
            };
            prod.push(i);
          });
          window.dataLayer.push({
            event: "eec.checkout",
            ecommerce: {
              checkout: {
                actionField: {
                  step: 3,
                  option: method,
                  action: "checkout",
                },
                products: prod,
              },
            },
          });
        }
      }
    },
    SubscribeNewsletter() {
      this.updateLocalStorage({ newsletter: this.SubscribeNewsletter });
    },
    paymentMethodOptions(method) {
      if (
        method &&
        method.multisafepay_ideal &&
        !method.multisafepay_ideal.issuer_id
      ) {
        if (this.localStorageData.issuer) {
          this.setPaymentMethodOptions(
            "multisafepay_ideal",
            "issuer_id",
            this.localStorageData.issuer
          );
        }
      }
    },
  },
  mounted() {
    this.setShippingMethod();
    const bcrumb = { current: this.$t("checkout"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
    if (this.$gtm.enabled()) {
      let prod = [];
      this.cartItems.forEach((element) => {
        let i = {
          id: element.product.sku,
          name: element.product.name,
          quantity: element.quantity,
        };
        prod.push(i);
      });
      window.dataLayer.push({
        event: "eec.checkout",
        ecommerce: {
          checkout: {
            actionField: {
              step: 1,
              option: "",
              action: "checkout",
            },
            products: prod,
          },
        },
      });
    }
    this.storage();
  },

  methods: {
    formateofGramm(data) {
      const final_calcultion = parseFloat(data.price_range.minimum_price.final_price.value) / parseInt(data.verkaufseinheit) * parseInt(data.grundeinheit);
      const text = `${data.verkaufseinheit} Gramm (${(final_calcultion).toFixed(2)} € / ${data.grundeinheit} Gramm)`
      return text
    },
    storage() {
      this.localStorageData =
        JSON.parse(localStorage.getItem("checkoutPayment")) ?? {};
      this.localStorageData.shipping
        ? (this.selectedShippingMethod = this.localStorageData.shipping)
        : "";
      this.localStorageData.delivery
        ? ((this.pickupLocation = this.localStorageData.delivery),
          (this.showPickupLocation = true),
          (this.selectedPickupLocation = this.localStorageData.delivery))
        : "";
      this.localStorageData.billing
        ? (this.selectedPaymentMethod = this.localStorageData.billing)
        : null;
      this.selectedIssuer = this.localStorageData.issuer
        ? this.localStorageData.issuer
        : "";
      this.localStorageData.newsletter
        ? (this.SubscribeNewsletter = this.localStorageData.newsletter)
        : (this.SubscribeNewsletter = "no");
    },
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount);
    },
    async submitForm() {
      this.buttonClicked = true;
      this.checkSiblingFields();
      if (this.checkFields()) {
        this.buttonClicked = true;
        if (this.selectedPaymentMethod == "paynl_payment_ideal") {
          this.$store.commit("cart/setCartPaymentMethodOptions", "");
        }
        const retval = await this.$store.dispatch("cart/addPaymentMethod");
        if (retval != false) {
          if(this.localStorageData.newsletter){
            if(this.$store.getters['user/getIsLoggedIn'] == true && !this.user.is_subscribed){
              this.$store.dispatch("user/newsletterSignup",{email : this.$store.getters['cart/getEmail']});
            }else{
              this.$store.dispatch("user/newsletterSignup",{email : this.$store.getters['cart/getEmail']});
            }
          }
          localStorage.removeItem("orderHash");
          if (retval.order.hash) {
            localStorage.setItem("orderHash", retval.order.hash);
          }

          const url = retval.redirect_url.replace(config.app_url, "");
          if (this.isLoggedIn == true) {
            this.$store.dispatch("user/loadOrders");
          }
          if (url.includes("https://")) {
            window.location = url;
          } else {
            this.$store.dispatch("cart/loadCart");
            this.$router.push({
              path: url + "?transactionid=" + retval.order.increment_id,
              params: { hash: retval.order.hash },
            });
          }
        }
        this.buttonClicked = false; //Re-enable button when addPaymentMethod fails
      }else{
        if (this.isLoggedIn == true && this.$refs.loggedInCheckoutDetails.newAddress == true) {
          await this.$refs.loggedInCheckoutDetails.checkDeliveryAddressFields();
        } else if (this.isLoggedIn == true && this.$refs.loggedInCheckoutDetails.newAddress != true){
          this.$store.commit("cart/setStoreValidation", {
            address: [],
          });
        }
        if (this.isLoggedIn == true && this.$refs.loggedInCheckoutDetails.newInvoiceAddress == true) {
          await this.$refs.loggedInCheckoutDetails.checkBillingAddressFields();
        }
        if (this.isLoggedIn != true) {
          await this.$refs.NotLoggedInCheckoutDetails.checkDeliveryAddressFields();
        }
      }
      this.buttonClicked = false; //Re-enable button when addPaymentMethod fails
    },
    async addCoupon() {
      if (this.couponCode != "") {
        const retval = await this.$store.dispatch("cart/addCouponCode", {
          code: this.couponCode,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("coupon_code"),
            text: this.$t("coupon_code_ok", { code: this.couponCode }),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.appliedCouponCode = this.couponCode;
          this.couponCode = "";
        }
      }
    },
    async addComment() {
      if (this.practitionerName != "") {
        await this.$store.commit("cart/setComment", "Name Behandler: " + this.practitionerName);
      }
    },
    setPaymentMethodOptions(paymentMethod, option_key, issuer_id) {
      if (paymentMethod) {
        this.updateLocalStorage({
          billing: paymentMethod,
          issuer: issuer_id,
        });
        this.$store.commit("cart/updateCartPaymentMethodOptions", {
          key: paymentMethod,
          sub: option_key,
          value: issuer_id,
        });
      }
    },
    checkAllSubOptions(code) {
      if (code == "multisafepay_ideal") {
        this.showPaymentSuboptions = "collapse show";
      } else {
        this.showPaymentSuboptions = "collapse hidden";
      }
      if (code == "multisafepay_klarna") {
        this.isKlarnaSelected = "collapse show";
      } else {
        this.isKlarnaSelected = "collapse hidden";
      }
      this.isPaymentSelected = true;
      this.showPaymentRequired = false;
    },
    submitCoupon(e) {
      e.preventDefault();
    },
    setShippingMethod() {
      if (this.showPickupLocation == false) {
        this.shippingMethod = this.selectedAddress;
      } else {
        if (this.user.addresses) {
          const defaultShipping = this.user.defaultShipping;
          const address = this.user.addresses.find(
            (add) => add.id == defaultShipping
          );
          this.shippingMethod = address;
        } else {
          this.shippingMethod = this.selectedAddress;
        }
      }
    },
    clearLocalStorage() {
      localStorage.removeItem("checkout");
    },
    updateLocalStorage(data) {
      this.localStorageData = Object.assign({}, this.localStorageData, data);
      localStorage.setItem(
        "checkoutPayment",
        JSON.stringify(this.localStorageData)
      );
    },
    async removeCouponCode() {
      await this.$store.dispatch("cart/removeCouponCode");
    },
    checkFields() {
      var errorFields = [];
      var storeValidation = this.$store.getters["cart/getStoreValidation"];
      for (const property in storeValidation) {
        storeValidation[property].forEach((error) => {
          errorFields.push(error);
        });
      }
      if (errorFields.length > 0) {
        const translatedErrors = errorFields.map(field => this.$t(field)).join(", ");
        const msg = {
          type: "danger",
          title: this.$t("Checkout fields incomplete"),
          text: `${this.$t("The following fields are invalid:")} ${translatedErrors}`,
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
        return false;
      }
      return true;
    },
    checkSiblingFields() {
      this.$root.$emit("checkFields");
      var errorFields = [];
      if (!this.shippingMethod) {
        errorFields.push(this.$t("Shipping method"));
      }
      if (!this.selectedPaymentMethod && !this.getSelectedPaymentMethod) {
        errorFields.push(this.$t("Payment method"));
      } else if (!this.selectedPaymentMethod) {
        this.selectedPaymentMethod = this.getSelectedPaymentMethod;
      }
      // if (this.selectedPaymentMethod == "multisafepay_ideal") {
      //   if (
      //     !this.paymentMethodOptions.multisafepay_ideal ||
      //     !this.paymentMethodOptions.multisafepay_ideal.issuer_id
      //   ) {
      //     errorFields.push(this.$t("Bank"));
      //   }
      // }
      if (errorFields.length > 0) {
        this.$store.commit("cart/setStoreValidation", {
          payment: errorFields,
        });
      } else {
        this.$store.commit("cart/setStoreValidation", {
          payment: [],
        });
      }
    },
    termsModal() {
      this.$bvModal.show("termsModal");
    },
    privacyModal() {
      this.$bvModal.show("privacyModal");
    },


  },

  data() {
    return {
      localStorageData: {},
      selectedShippingMethod: null,
      selectedIssuer: null,
      pickupLocation: null,
      practitionerName: "",
      showPickupLocation: false,
      selectedPickupLocation: null,
      couponCode: "",
      app_url: config.app_url,
      paymentSubOptions: {},
      SubscribeNewsletter: "no",
      carrier_code: "",
      buttonClicked: false,
    };
  },
  metaInfo() {
    return {
      title: this.$t("checkout"),
      // all titles will be injected into this template
    };
  },
};
</script>
